export const getHeader = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.trim()
        },
    }
}

async function doRefreshToken(refresh, onSuccessCallback, onFailureCallback) {
    // function that refreshes the token and tries to reload the page
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/token/refresh/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh })
    })
    .then(async response => ({
        error: response.status !== 200,
        data: await response.json()
    }))
    .then(({ error, data }) => {
        if (!error) {
            onSuccessCallback(data.access);
            window.location.reload();
        } else {
            onFailureCallback();
        }
    })
}

export const handleInvalidToken = (onFailureCallback, onRefreshCallback, refreshToken) => (response) => {
    if (!response.ok) {
        if ([401, 403].includes(response.status)) {
            // try to refresh the token
            doRefreshToken(refreshToken, onRefreshCallback, () => {
                if (onFailureCallback) onFailureCallback();
                window.location = '/login';
            });
            return Promise.reject();
        }
    }
    return response;
}

export const generateUrl = (url, params) => {
    const serialize = (obj) => {
        var str = [];
        for(var p in obj)
           str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        return str.join("&");
      }
    return url + '?' + serialize(params);
}