import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pickBy } from 'lodash';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './components/Login';
import Brands from "./components/Brands";
import Groups from './components/Groups';
import SubGroups from './components/SubGroups';
import ProductList from './components/ProductList';
import { setDownloaded } from './actions';
import './App.css';

//declare 'sketchup' object as global to stop ESLint errors
/*global sketchup*/

class App extends Component {
  state = {
    token: null,
    refreshToken: null,
    tokenExpired: false,
  };

  componentWillMount = () => {
    // load the token from the URL params if it exists
    window.setDownloaded = this.handleSetDownloaded;
    window.saveToken = this.handleSaveToken;
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh');

    const params = new URLSearchParams(window.location.search);
    if (!this.state.token){
      if (token) {
        this.setState(pickBy({ token, refreshToken }));
      }
      else if (params.get('token')){
        this.setState(pickBy({
          token: params.get('token'),
          refreshToken: params.get('refresh')
        }));
      }
      else if (!this.state.tokenExpired && token !== "expired") {
        // maybe we can request the token from ruby??
        this.handleRequestToken();
      }
      if (params.get('cached')) {
        this.handleSetDownloaded(params.get('cached'));
      } else {
        this.handleRequestComponents();
      }
    }

    if (token !== "expired") {
      this.setState({ token });
      window.setDownloaded = this.handleSetDownloaded;
    } else {
      this.setState({ tokenExpired: true });
    }
  };

  handleTokenExpired = () => {
    this.setState({ token: null, tokenExpired: true });
    localStorage.setItem("token", "expired");
  };

  handleRequestToken = () => {
    try {
      // fetch token from ruby if it exists
      sketchup.requestCachedToken();
    } catch (e) {
      // ignore 'sketchup is not defined' in development
      // but report other errors
      console.warn('Sketchup functions only work on Sketchup Application');
    }
  };

  handleRequestComponents = () => {
    try {
      // fetch downloaded components from ruby
      sketchup.requestCachedComponents();
    } catch (e) {
      // ignore 'sketchup is not defined' in development
      // but report other errors
      console.warn('Sketchup functions only work on Sketchup Application');
    }
  };

  handleSaveToken = (token, refreshToken) => {
    this.setState(pickBy({ token, refreshToken }));
    localStorage.setItem("token", token);
    if (refreshToken)
      localStorage.setItem("refresh", refreshToken);
  };

  handleSetDownloaded = (data) => {
    this.props.setDownloaded(data);
    localStorage.setItem("downloaded", data);
  };

  render() {
    const tokenProps = {
      token: this.state.tokenExpired ? null: this.state.token,
      handleTokenExpired: this.handleTokenExpired,
      handleSaveToken: this.handleSaveToken,
      refreshToken: this.state.refreshToken,
    };
    return (
      <Router>
        <div>
          <Route
            exact path="/login"
            render={(props) => <Login {...props} {...tokenProps}/>} />
          <Route
            exact path="/"
            render={(props) => <Brands {...props} {...tokenProps} />} />
          <Route
            exact path="/groups"
            render={(props) => <Groups {...props} {...tokenProps} />} />
           <Route
            exact path="/sub-groups"
            render={(props) => <SubGroups {...props} {...tokenProps} />} />
          <Route
            exact path="/products"
            render={(props) => <ProductList {...props} {...tokenProps} />}
          />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    downloaded: state.getIn(['products', 'downloaded'], []),
  }
};

const mapDispatchToProps = {
  setDownloaded
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
