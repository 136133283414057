import React from 'react';
import PropTypes from 'prop-types';
import checkMark from '../images/check.png';
import fileNotFound from '../images/file_not_found.png';
import cx from 'classnames';

//declare 'sketchup' object as global to stop ESLint errors
/*global sketchup*/

class Product extends React.Component {
  state = {
    downloading: false
  };

  handleDownloadComponent = (code, downloadUrl) => {
    try {
      sketchup.downloadComponent(code, downloadUrl);
    } catch (e) {
      // ignore 'sketchup is not defined' in development
      // but report other errors
      console.warn('Sketchup functions only work on Sketchup Application');
    }
  };

  handleAddComponent = (code, otherData) => {
    try {
      sketchup.insertComponent(code, otherData);
    } catch (e) {
      // ignore 'sketchup is not defined' in development
      // but report other errors
      console.warn('Sketchup functions only work on Sketchup Application');
    }
  };

  handleDrag = (code, downloadUrl) => {
    try {
      sketchup.dragComponent(code, downloadUrl);
    } catch (e) {
      // ignore 'sketchup is not defined' in development
      // but report other errors
      console.warn('Sketchup functions only work on Sketchup Application');
    }
  };

  render() {
    const { partNumber, description, thumbnail, isDownloaded, downloadUrl, otherData } = this.props;
    const id = 'toolTip' + partNumber.replace('-', '');
    const imgClassName = cx('image', {
        'img-thumbnail': !!downloadUrl,
        'img-unavailable': !downloadUrl,
        'img-saved': isDownloaded,
        'img-unsaved': !isDownloaded,
    });

    return (
      <div className="product">
        {
          thumbnail ?
          <div>
            <img
              id={id}
              src={thumbnail}
              alt={description}
              className={imgClassName}
            ></img>
            { isDownloaded ? <img className="img-check" src={checkMark} alt="Done" />: ''}
            <div
              className="overlay"
              onClick={e => isDownloaded ?
                this.handleAddComponent(partNumber, {...otherData, itemcode: partNumber }):
                  downloadUrl ? this.handleDownloadComponent(partNumber, downloadUrl) :
                  alert('Skp file for this component is not available.') }
            >
              <div className="text">
                <h4>{partNumber}</h4>
                <p>{description}</p>
                { isDownloaded ?
                  <p className="label">Click to <br/><strong>RENDER</strong></p>:
                downloadUrl ?
                  <p className="label">Click to <br/><strong>DOWNLOAD</strong></p>:
                  <p className="label-unavailable">No dynamic component available</p> }
              </div>
            </div>

          </div>:
          <div>
            <img src={fileNotFound} alt="Not Found" className="image img-thumbnail" />
            <div className="overlay">
              <div className="text">
                <h5>{partNumber}</h5>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

Product.propTypes = {
  part_number: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.string,
};

export default Product;
