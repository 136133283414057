import { SET_DOWNLOADED } from './actions';
import { Record, List } from 'typed-immutable';

const Product = Record({
    id: Number,
    part_number: String,
    is_downloaded: Boolean(false),
    dynacomp_url: String,
})

const StateProduct = Record({
    all: List(Product),
    downloaded: List(String),
});

const State = Record({
    products: StateProduct
}, "State");

function product(state=StateProduct(), action) {
    switch(action.type) {
        case SET_DOWNLOADED:
            return state.set('downloaded', action.data.split(','));
        default:
            return state;
    }
}

function catalog(state = State(), action) {
    return State({
        products: product(state.products, action)
    })
}

export default catalog;