import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import backButtonImage from './images/backbutton.png';
import downloadButtonImage from './images/download-icon.png';

class Navigation extends React.Component {
  constructor (props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  goBack () {
    this.props.history.goBack();
  }

  displayBackButton() {
    return this.props.history && this.props.location.pathname !== '/' ?
      <div>
        <Button color="link" onClick={this.goBack}>
          <img src={backButtonImage} alt="Go Back" />
        </Button>
      </div>
    :
      <span />
  }

  displayDownloadButton() {
    return this.props.isDownloadable && this.props.location.pathname.startsWith('/products') ?
      <div className="float-right navigation-download-all">
        <Button color="link" onClick={this.props.downloadAll}>
          <img src={downloadButtonImage} alt="Download All" />
        </Button>
      </div>
    :
      <span />
  }

  buildNavigationPath(path) {
    const links = [];

    const n = path.length;
    path.forEach((entry, i) => {
      const key = 2 * i + 1;
      const style = { cursor: 'pointer' };
      const onClick = e => {
        e.preventDefault();
        e.stopPropagation();
        for (let j = 0; j < n - i - 1; ++j) this.goBack();
      };
      const className = 'navigation-path-item';
      links.push(<span key={key} style={style} onClick={onClick} className={className}>{entry}</span>);
      links.push(<span key={key + 1}> &gt; </span>);
    });

    if (links.length > 0) {
      links.splice(-1, 1);
    }

    return links;
  };

  render() {
    const { path, title } = this.props;
    return (
      <div className="navigation-bar">
        {this.displayBackButton()}
        <div className="navigation-path">{this.buildNavigationPath(path)}</div>
        {this.displayDownloadButton()}
        <span className="navigation-title">{title}</span>
      </div>
    );
  }
}

Navigation.propTypes = {
  title: PropTypes.string
};

export default Navigation;
