import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import chunk from 'lodash/chunk';
import { Container, Row, Col } from 'reactstrap';
import Navigation from '../Navigation';
import groupFolder from '../images/group_folder.png';
import { getHeader, handleInvalidToken } from './helpers';
import queryString from 'query-string';

const NUMBER_ITEM_PER_ROWS = 4;

class SubGroups extends React.Component {
  _isMounted = false;

  state = {
    sub_groups: [],
    brand: undefined,
    group: undefined,
    loading: true
  };

  componentWillMount() {
    this._isMounted = true;

    const queryParams = queryString.parse(this.props.location.search);

    const brand = queryParams.brand;
    const group = queryParams.group;
    if (this._isMounted) {
      this.setState({ brand, group });
    }

    if (!this.props.token) {
      return;
    }

    const dummy = {id: '', name: 'N/A'};

    if (group) {
      // get the list of sub-groups under this group
      const sub_groups_url = `${process.env.REACT_APP_API_ENDPOINT}/api/part-groups/all/?parent=${group}`;

      fetch(sub_groups_url, getHeader(this.props.token))
      .then(handleInvalidToken(this.props.handleTokenExpired, this.props.handleSaveToken, this.props.refreshToken))
      .then(response => response.json())
      .then(data => {
        data.push(dummy);
        if (this._isMounted) {
          this.setState({ sub_groups: data, loading: false });
        }
      }, () => null);
    } else {
      if (this._isMounted) {
        this.setState({ sub_groups : [dummy], loading: false });
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  };

  buildRouterQueryString = (group) => {
    const queryParams = [];

    if (typeof this.state.brand === 'string') {  // empty string is also OK
      queryParams.push(`brand=${this.state.brand}`);
    }

    if (typeof this.state.group === 'string') {   // empty string is also OK
      queryParams.push(`group=${this.state.group}`);
    }

    queryParams.push(`sub_group=${group.id}`);

    let queryString = queryParams.join('&');
    if (queryString) {
      queryString = '?' + queryString;
    }
    return queryString;
  };

  buildRouterState = (group) => {
    return { sub_group: group.name, ...this.props.location.state };
  };

  buildRouterLink = (group) => {
    return {
      pathname: '/products',
      search: this.buildRouterQueryString(group),
      state: this.buildRouterState(group)
    }
  };

  renderGroupCol = (group, key) => {
    return (
      <Col key={key}>
        <Link className="product-group" to={this.buildRouterLink(group)}>
          <img src={groupFolder} alt={group.name} className="image img-thumbnail"/>
          <div className="overlay">
            <div className="text">{group.name}</div>
          </div>
        </Link>
      </Col>
    );
  };

  renderGroupRow = (groups, key) => {
    const cols = [];

    for (let index = 0; index < NUMBER_ITEM_PER_ROWS; index++) {
      const group = groups[index];
      const col = group ? this.renderGroupCol(group, index) : <Col key={index}></Col>;
      cols.push(col);
    }

    return (
      <Row key={key}>
        {cols}
      </Row>
    );
  };

  render() {
    if (!this.props.token) {
      return <Redirect to='/login' />;
    }

    const { brand, group } = this.props.location.state;

    const path = ['Home', brand, group];
    const title = `${group} Sub Groups`;

    if (this.state.loading) {
      return (
        <div>
          <Navigation {...this.props} path={path} title={title} />
          <Container fluid>
            <h4 className="container-title">Loading...</h4>
          </Container>
        </div>
      );
    }

    const groupsByRows = chunk(this.state.sub_groups, NUMBER_ITEM_PER_ROWS);
    const rows = groupsByRows.map((groupsByRow, index) => {
      return this.renderGroupRow(groupsByRow, index);
    });

    return (
      <div>
        <Navigation {...this.props} path={path} title={title} />
        <Container fluid>
          {rows}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sub_groups: state.sub_groups
  }
};

export default connect(mapStateToProps)(SubGroups);
