import React from 'react';
// import { Redirect } from 'react-router-dom';
import './login.css';

//declare 'sketchup' object as global to stop ESLint errors
/*global sketchup*/

class Login extends React.Component {
    state = {
        credentials: {
            username: '',
            password: ''
        },
        error_msg: null
    }

    handleSubmit = (e) => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/token/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state.credentials)
        })
        .then(async response => ({
            error: response.status !== 200,
            data: await response.json()
        }))
        .then(({ error, data }) => {
            this.setState({
                error_msg: error ? data.detail: null,
                credentials: {
                    username: '',
                    password: ''
                },
            })
            this.props.handleSaveToken(data.access, data.refresh);

            if (!error) {
                // save the data to the extension
                try {
                    sketchup.saveTokens(data);
                } catch (e) {
                    // ignore 'sketchup is not defined' in development
                    // but report other errors
                    console.warn('Sketchup functions only work on Sketchup Application');
                }
                const fake_url = 'skp:saveTokens@' + JSON.stringify(data);
                window.location.href = fake_url;
                setTimeout(() => window.location = "/", 200);
            }
        })
    }

    handleChange = (e) => {
        const data = {[e.target.name] : e.target.value};
        this.setState(prevState => {
            return {
                credentials: {
                    ...prevState.credentials,
                    ...data
                }
            }
        });
    }

    render() {
        // if (this.props.token !== null) {
        //     console.log("redirecting");
        //     return <Redirect to={`/?token=${this.props.token}`} />;
        // }

        return (
            <div className="login-form">
                { this.state.error_msg ? <div className="error"> {this.state.error_msg}</div>: '' }
                <form onSubmit={this.handleSubmit}>
                    <h2 className="text-center">Log in</h2>
                    <div className="form-group">
                        <input
                            name="username"
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            required
                            value={this.state.credentials.username}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            value={this.state.credentials.password}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block">Log in</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Login;
