import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import chunk from 'lodash/chunk';
import { Container, Row, Col } from 'reactstrap';
import Navigation from '../Navigation';
import brandFolder from '../images/brand_folder.png';
import { getHeader, handleInvalidToken } from './helpers';

const NUMBER_ITEM_PER_ROWS = 4;

class Brands extends React.Component {
  _isMounted = false;

  state = {
    brands: [],
    loading: true
  };

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.token) {
      return;
    }

    const dummy = {id: '', name: 'N/A'};

    const brands_url = `${process.env.REACT_APP_API_ENDPOINT}/api/part-brands/`;

    fetch(brands_url, getHeader(this.props.token))
    .then(handleInvalidToken(this.props.handleTokenExpired, this.props.handleSaveToken, this.props.refreshToken))
    .then(response => response.json())
    .then(data => {
      data.push(dummy);
      if (this._isMounted) {
        this.setState({ brands: data, loading: false });
      }
    }, () => null);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  buildRouterQueryString = (brand) => {
    return `?brand=${brand.id}`;
  };

  buildRouterState = (brand) => {
    return { brand: brand.name };
  };

  buildRouterLink = (brand) => {
    return {
      pathname: '/groups',
      search: this.buildRouterQueryString(brand),
      state: this.buildRouterState(brand)
    }
  };

  renderBrandCol = (brand, key) => {
    return (
      <Col key={key}>
        <Link className="product-brand" to={this.buildRouterLink(brand)}>
          <img src={brandFolder} alt={brand.name} className="image img-thumbnail"/>
          <div className="overlay">
            <div className="text">{brand.name}</div>
          </div>
        </Link>
      </Col>
    );
  };

  renderBrandRow = (brands, key) => {
    const cols = [];

    for (let index = 0; index < NUMBER_ITEM_PER_ROWS; index++) {
      const brand = brands[index];
      const col = brand ? this.renderBrandCol(brand, index) : <Col key={index}></Col>;
      cols.push(col);
    }

    return (
      <Row key={key}>
        {cols}
      </Row>
    );
  };

  render() {
    if (!this.props.token) {
      return <Redirect to='/login' />;
    }

    const path = ['Home'];
    const title = 'KidsTale Brands';

    if (this.state.loading) {
      return (
        <div>
          <Navigation {...this.props} path={path} title={title} />
          <Container fluid>
            <h4 className="container-title">Loading...</h4>
          </Container>
        </div>
      );
    }

    const brandsByRows = chunk(this.state.brands, NUMBER_ITEM_PER_ROWS);
    const rows = brandsByRows.map((brandsByRow, index) => {
      return this.renderBrandRow(brandsByRow, index);
    });

    return (
      <div>
        <Navigation {...this.props} path={path} title={title} />
        <Container fluid>
          {rows}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    brands: state.brands
  }
};

export default connect(mapStateToProps)(Brands);
