// action types
export const DOWNLOAD_COMPONENT = 'DOWNLOAD_COMPONENT';
export const CACHE_GROUP = 'CACHE_GROUP';
export const CACHE_PRODUCT = 'CACHE_PRODUCT';
export const SET_DOWNLOADED = 'SET_DOWNLOADED';

// actions
export function setDownloaded(data) {
    return {
        type: SET_DOWNLOADED,
        data
    }
}