import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import chunk from 'lodash/chunk';
import { Container, Row, Col } from 'reactstrap';
import Product from './Product';
import Navigation from '../Navigation';
import { getHeader, handleInvalidToken } from './helpers';
import queryString from 'query-string';

const PART_STATUS_LIVE = 'live';
const NUMBER_ITEM_PER_ROWS = 4;

//declare 'sketchup' object as global to stop ESLint errors
/*global sketchup*/

class ProductList extends React.PureComponent {
  _isMounted = false;

  state = {
    products: [],
    brand: undefined,
    group: undefined,
    sub_group: undefined,
    loading: true
  };

  buildAPIQueryString = (brand, group, sub_group) => {
    const queryParams = [];

    if (typeof brand === 'string') {  // empty string is also OK
      if (brand === '') {
        queryParams.push('brand__isnull');
      } else {
        queryParams.push(`brand=${brand}`);
      }
    }

    if (typeof group === 'string') {  // empty string is also OK
      if (group === '') {
        queryParams.push('group__isnull');
      } else {
        queryParams.push(`group=${group}`);
      }
    }

    if (typeof sub_group === 'string') {  // empty string is also OK
      if (sub_group === '') {
        queryParams.push('sub1_group__isnull');
      } else {
        queryParams.push(`sub1_group=${sub_group}`);
      }
    }

    queryParams.push(`status=${PART_STATUS_LIVE}`);

    let queryString = queryParams.join('&');
    if (queryString) {
      queryString = '?' + queryString;
    }
    return queryString;
  };

  componentDidMount() {
    this._isMounted = true;

    const queryParams = queryString.parse(this.props.location.search);

    const brand = queryParams.brand;
    const group = queryParams.group;
    const sub_group = queryParams.sub_group;
    if (this._isMounted) {
      this.setState({ brand, group, sub_group });
    }

    if (!this.props.token) {
      return;
    }

    const parts_url = `${process.env.REACT_APP_API_ENDPOINT}/api/parts/${this.buildAPIQueryString(brand, group, sub_group)}`;

    const fetchPage = (url, page = 1) => {
      fetch(url + (url.indexOf('?') === -1 ? '?' : '&') + `page=${page}`, getHeader(this.props.token))
      .then(handleInvalidToken(this.props.handleTokenExpired, this.props.handleSaveToken, this.props.refreshToken))
      .then(response => response.json())
      .then(data => {
        const products = this.state.products;
        data.results.forEach(product => products.push(product));
        const done = products.length === data.count;
        const callback = () => {
          if (done) {
            if (this._isMounted) {
              this.setState({ loading: false });
            }
          } else {
            fetchPage(url, page + 1);
          }
        };
        if (this._isMounted) {
          this.setState({ products: products }, callback);
        }
      }, () => null);
    };

    fetchPage(parts_url);
  };

  componentWillUnmount() {
    this._isMounted = false;
  };

  handleDownloadAll = () => {
    try {
      sketchup.downloadAll(this.state.products.map(p => p.part_number + ' ' + p.dynacomp_url));
    } catch (e) {
      // ignore 'sketchup is not defined' in development
      // but report other errors
      console.warn('Sketchup functions only work on Sketchup Application');
    }
  };

  renderProductCol = (product, key) => {
    const { part_number, description, thumbnail, dynacomp_url, ...otherData } = product;
    const { downloaded } = this.props;
    return (
      <Col key={key}>
        <Product
          partNumber={part_number}
          description={description}
          thumbnail={thumbnail}
          isDownloaded={ downloaded.includes(part_number) }
          downloadUrl={dynacomp_url}
          otherData={otherData}
        />
      </Col>
    );
  };

  renderProductRow = (products, key) => {
    const cols = [];

    for (let index = 0; index < NUMBER_ITEM_PER_ROWS; index++) {
      const product = products[index];
      const col = product ? this.renderProductCol(product, index) : <Col key={index}></Col>;
      cols.push(col);
    }

    return (
      <Row key={key}>
        {cols}
      </Row>
    );
  };

  render() {
    if (!this.props.token) {
      return <Redirect to='/login' />;
    }

    const { brand, group, sub_group } = this.props.location.state;

    const path = ['Home', brand, group, sub_group];
    const title = `${sub_group}`;

    if (this.state.loading) {
      return (
        <div>
          <Navigation {...this.props} path={path} title={title} />
          <Container fluid>
            <h4 className="container-title">Loading...</h4>
          </Container>
        </div>
      );
    }

    const productByRows = chunk(this.state.products, NUMBER_ITEM_PER_ROWS);
    const rows = productByRows.map((productByRow, index) => {
      return this.renderProductRow(productByRow, index);
    });

    const { downloaded } = this.props;

    return (
      <div>
        <Navigation
          {...this.props}
          isDownloadable={
            // are there any parts that have not yet been downloaded
            this.state.products.some(p => !downloaded.includes(p.part_number))
          }
          downloadAll={this.handleDownloadAll}
          path={path}
          title={title} />
        <Container fluid>
          {
            rows.length ? rows :
            <div>
              <h4 className="container-title">No products found</h4>
            </div>
          }
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    downloaded: state.getIn(['products', 'downloaded'], []),
  }
};

export default connect(mapStateToProps)(ProductList);
